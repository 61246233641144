
<div class="full-width">
  <div class="page-title">
      <div class="container">
        <h3>
           {{'GENERAL_PAGES.CONTACT.CONTACT_US'|translate}}
        </h3>
       
      </div>
  </div>
</div>
<div class="contact">
    <div class="container">
          <div class="card">
            <div class="row">
              <div class="col-md-8 col-sm-12 col-xs-12 pull-left text-left" id="left">
                <h4 class="main-title uppercase bold">
                  {{'GENERAL_PAGES.CONTACT.MAIN_BRANCH'|translate}}

                </h4>
                <div class="branch">
                  <!-- <h6 class="uppercase bold">المركز الرئيسي</h6> -->
                  <div class="contact-info">
                    <p><i class="fa fa-envelope gold"></i> <b>الصندوق البريدي:</b> &nbsp;<span class="Numbers-font"> {{contactData?.postal_code}}</span></p>
                    <p><i class="fa fa-phone gold"></i> <b>رقم الهاتف :</b> &nbsp;<span class="Numbers-font">{{contactData?.landline}}</span><span class="vertical_separator"></span><span class="Numbers-font">{{contactData?.mobile}}</span></p>
                    <p><i class="fa fa-fax gold"></i> <b>فاكس:</b> &nbsp;<span class="Numbers-font">{{contactData?.fax}}</span></p>
                    <p><i class="fa fa-envelope gold"></i> <b>البريد الإلكتروني:</b> &nbsp;<span>{{contactData?.email}}</span></p>
                    <p><i class="fa fa-map-marker gold"></i> <b>العنوان:</b>  &nbsp;<span style="font-family: Arial !important;">{{contactData?.address}}</span></p>
                  </div>
                </div>
                <!-- <div class="branches">
                  <h5 class="uppercase bold">{{'GENERAL_PAGES.CONTACT.OTHER_BRANCHES'|translate}}</h5>
                  <div class="scroll">
                    <div class="branch">
                      <h6 class="uppercase bold"> Reyadh Branch</h6>
                      <div class="contact-info">
                        <p><i class="fa fa-phone gold"></i><span>195344345</span><span class="vertical_separator"></span><span>1953353454</span></p>
                        <p><i class="fa fa-envelope gold"></i><span>info.charitybox.com</span><span class="vertical_separator"></span><span>info.charitybox.com</span><span class="vertical_separator"></span><span>info.charitybox.com</span></p>
                        <p><i class="fa fa-map-marker gold"></i><span>12 street - Reyadh -Saudia Arabia</span></p>
                      </div>
                    </div>
                    <div class="branch">
                      <h6 class="uppercase bold"> Reyadh Branch</h6>
                      <div class="contact-info">
                        <p><i class="fa fa-phone gold"></i><span>195344345</span><span class="vertical_separator"></span><span>1953353454</span></p>
                        <p><i class="fa fa-envelope gold"></i><span>info.charitybox.com</span><span class="vertical_separator"></span><span>info.charitybox.com</span><span class="vertical_separator"></span><span>info.charitybox.com</span></p>
                        <p><i class="fa fa-map-marker gold"></i><span>12 street - Reyadh -Saudia Arabia</span></p>
                      </div>
                    </div>
                    <div class="branch">
                      <h6 class="uppercase bold"> Reyadh Branch</h6>
                      <div class="contact-info">
                        <p><i class="fa fa-phone gold"></i><span>195344345</span><span class="vertical_separator"></span><span>1953353454</span></p>
                        <p><i class="fa fa-envelope gold"></i><span>info.charitybox.com</span><span class="vertical_separator"></span><span>info.charitybox.com</span><span class="vertical_separator"></span><span>info.charitybox.com</span></p>
                        <p><i class="fa fa-map-marker gold"></i><span>12 street - Reyadh -Saudia Arabia</span></p>
                      </div>
                    </div>
                    <div class="branch">
                      <h6 class="uppercase bold"> Reyadh Branch</h6>
                      <div class="contact-info">
                        <p><i class="fa fa-phone gold"></i><span>195344345</span><span class="vertical_separator"></span><span>1953353454</span></p>
                        <p><i class="fa fa-envelope gold"></i><span>info.charitybox.com</span><span class="vertical_separator"></span><span>info.charitybox.com</span><span class="vertical_separator"></span><span>info.charitybox.com</span></p>
                        <p><i class="fa fa-map-marker gold"></i><span>12 street - Reyadh -Saudia Arabia</span></p>
                      </div>
                    </div>
                    <div class="branch">
                      <h6 class="uppercase bold"> Reyadh Branch</h6>
                      <div class="contact-info">
                        <p><i class="fa fa-phone gold"></i><span>195344345</span><span class="vertical_separator"></span><span>1953353454</span></p>
                        <p><i class="fa fa-envelope gold"></i><span>info.charitybox.com</span><span class="vertical_separator"></span><span>info.charitybox.com</span><span class="vertical_separator"></span><span>info.charitybox.com</span></p>
                        <p><i class="fa fa-map-marker gold"></i><span>12 street - Reyadh -Saudia Arabia</span></p>
                      </div>
                    </div>
                    <div class="branch">
                      <h6 class="uppercase bold"> Reyadh Branch</h6>
                      <div class="contact-info">
                        <p><i class="fa fa-phone gold"></i><span>195344345</span><span class="vertical_separator"></span><span>1953353454</span></p>
                        <p><i class="fa fa-envelope gold"></i><span>info.charitybox.com</span><span class="vertical_separator"></span><span>info.charitybox.com</span><span class="vertical_separator"></span><span>info.charitybox.com</span></p>
                        <p><i class="fa fa-map-marker gold"></i><span>12 street - Reyadh -Saudia Arabia</span></p>
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
                <div class="col-md-4 col-sm-12 col-xs-12 pull-right">
                  <div class="map map-home" id="mapid">
                    <!-- <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" >
                    <agm-marker [latitude]="latitude" [longitude]="longitude"></agm-marker>
                  </agm-map> -->
                  <div #mapContainer id="map"></div>
                </div>
              </div> 
            </div>
          </div>
          <div class="clearfix"></div>
         <!--end-->
        <!-- <br> -->
        <div class="card">
          <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <h4 class="main-title text-left">
                {{'GENERAL_PAGES.CONTACT.HELP'|translate}}
              </h4>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <form class="custom full-width form-padding" [formGroup]="contactForm" (ngSubmit)="submit(contactForm.value)" enctype="multipart/form-data">
  
                <div class="form-group ">
                    <label class="edit_color pull-left contact-padding">{{'GENERAL_PAGES.CONTACT.NAME'|translate}}</label>
                    <input type="text" formControlName="name"  class="form-control">
                </div>
                <div class="form-group ">
                  <label class="edit_color pull-left contact-padding">{{'GENERAL_PAGES.CONTACT.EMAIL'|translate}}</label>
                  <input type="email" formControlName="email" class="form-control">
                  <!--Validation-->
                  <span *ngIf="contactForm.get('email').touched && contactForm.get('email').invalid">
                    <small *ngIf="contactForm.get('email').errors.required" class="form-text required">

                        برجاء ادخال البريد الإلكتروني
                    </small>
                    <small *ngIf="contactForm.get('email').errors.pattern"  class="form-text required">
                        برجاء ادخال بريد الإلكتروني  صحيح
                    </small>
                </span>
                </div>
                <div class="form-group ">
                  <label class="edit_color pull-left contact-padding">{{'GENERAL_PAGES.CONTACT.PHONE'|translate}}</label>
                  <input type="text" (keypress)="acceptOnlyNumbers($event)" formControlName="mobile" maxlength="10" class="form-control Numbers-font">
                 
                  <span *ngIf="contactForm.get('mobile').touched && contactForm.get('mobile').invalid">
                    <small *ngIf="contactForm.get('mobile').errors.required" class="form-text required">
                        برجاء ادخال رقم هاتف
                    </small>
                    <small *ngIf="contactForm.get('mobile').errors.pattern"  class="form-text required">
                      برجاء ادخال رقم هاتف صحيح
                    </small>
                 </span>

                </div>
  
                <div class="form-group more-padding">
                    <label class="edit_color pull-left contact-padding">{{'GENERAL_PAGES.CONTACT.SUBJECT'|translate}}</label>
                    <select [(ngModel)]="message_id" [ngModelOptions]="{standalone: true}" class="custom-select" placeholder="اختر عنوان الرسالة">
                             <!-- <option value="0">اختر عنوان الرسالة</option> -->
                             <option  *ngFor="let messagestitles of message_title" [value]="messagestitles.id">{{messagestitles.name}} </option>
                    </select>
                    <!-- <input type="text" formControlName="title"  class="form-control"> -->

                </div>
  
                <div class="form-group ">
                  <label class="edit_color pull-left contact-padding">{{'GENERAL_PAGES.CONTACT.MESSAGE'|translate}}</label>
                  <textarea class="form-control" formControlName="message" ></textarea>
                </div>
  
                <div class="form-group ">
                  <div class="row">
                    <div class="col-md-12 col-xs-12">
                      <label class="edit_color pull-left contact-padding">{{'GENERAL_PAGES.CONTACT.ATTACHMENT'|translate}}</label>
                    </div>

                  </div>
                  <!-- <input type="file" (change)="readImage($event)"  class="form-control" formControlName="document"> -->
                   <div class="row">
                     <div class="col-md-12 col-sm-12 col-xs-12 pull-left">
                      <div class="upload-btn-wrapper form-control" >
                        <button  class="btnr" title="اختر ملف" type="button" >اختر ملف</button>
                        <span *ngIf="uploaded_file == false">لم تقم باختيار ملف</span>
                        <span>{{fileName}}</span>
                        <input type="file" (change)="readImage($event)"  class="form-control" formControlName="document" accept="application/msword,application/pdf,.doc, .docx , .pdf , .png , .jpg , .jpeg" />
                      </div>
                     </div>
                   </div>
                  
                </div>

                <!-- <div class="row"> -->

                  <div class="form-group">
                    <label class="edit_color pull-left contact-padding">{{'Please Answer The Following Equation'|translate}}</label>
                    <input type="text" formControlName="answer" placeholder="{{RandomNumber1}} + {{RandomNumber2}} = ........" class="form-control Numbers-font">                  
                  </div>

                <!-- </div> -->
  
                <button type="button" class="btn-custom"  type="submit" style="border-radius: 7px !important;"> {{'GENERAL_PAGES.CONTACT.SEND'|translate}} </button>
                <!-- <button type="button" class="btn-charitybox-dark"> {{'GENERAL_PAGES.CONTACT.CANCEL'|translate}} </button> -->
  
            </form>
            </div>
          </div>
       </div>

    </div>

</div>

