import { Injectable } from '@angular/core';
import { BaseHttpService } from 'src/app/services/base-http.service';


@Injectable({
  providedIn: 'root'
})
export class SupportService {

  constructor(private baseHttpService: BaseHttpService) { }
   
  getSupportList() {
    return this.baseHttpService.post('/api/support_types', {
      "module_id": 490
    }, {})
  }

  getSupportByID(id) {
    return this.baseHttpService.post('/api/support_types/show', {
      "module_id": 490,
      "id": id
    }, {})
  }
  
  getDonationList() {
    return this.baseHttpService.post('/api/donation_types', {
      "module_id": 510
    }, {})
  }
 
  GetSupportTitle(){
    return this.baseHttpService.post('/api/pages/show', {
      "id" : 15,
    }, {})
  }


}
