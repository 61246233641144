import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators , AbstractControl } from '@angular/forms';
import {AuthenticationService} from '../../services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  registrationForm: FormGroup;
  UserInfo;
  UserData;
  UserID;
  
  oldpassword : any = 'password';
  password: any = 'password';
  confirmPassword: string = 'password';


  constructor(
              formBuilder:FormBuilder,
             private authService: AuthenticationService,
             private toastr: ToastrService,
             private router: Router,
             private route: ActivatedRoute
            ) {

              this.registrationForm  = formBuilder.group({
      
                old_password:['',Validators.compose([Validators.required])],
                password:['',Validators.compose([Validators.required,Validators.pattern('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{11,}$')  ])],
                confirm_password:['',Validators.compose([Validators.required,Validators.pattern('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{11,}$')  ])],
              },
              {validators: this.passwordMatching}
              )
               
             }

  ngOnInit(): void {
    this.UserInfo =localStorage.getItem('user_info');
    console.log("User Information:",JSON.parse(this.UserInfo)  )
    this.UserData = JSON.parse(this.UserInfo)
    this.UserID = this.UserData.id;
    console.log("User ID:-",this.UserID)
  }

  changoldPasswordVisiabilty() {
    if (this.oldpassword == 'password') {
      this.oldpassword = 'text'
    } else {
      this.oldpassword = 'password'
    }
  }


  changPasswordVisiabilty() {
    if (this.password == 'password') {
      this.password = 'text'
    } else {
      this.password = 'password'
    }
  }

  changConfirmPasswordVisiabilty() {
    if (this.confirmPassword == 'password') {
      this.confirmPassword = 'text'
    } else {
      this.confirmPassword = 'password'
    }
  }

  passwordMatching(form: AbstractControl) {
    if (form.get('confirm_password').errors && !form.get('confirm_password').errors.notMatch) {
      // return if another validator has already found an error on the confirmation control
      return;
    }
    if (form.get('confirm_password').value !== form.get('password').value) {
      form.get('confirm_password').setErrors({ notMatch: true })
      return { invalid: true }
    } else {
      form.get('confirm_password').setErrors(null)
      return;
    }
  }



  submit(value: any) {
    
    if (value.invalid && value.pristine) {
      value.markAllAsTouched()
    } else if (value.invalid && value.dirty) {
      Object.keys(value.controls).forEach(field => {
        const control = value.get(field);
        control.markAsTouched({onlySelf: true});
      });
      this.toastr.error('من فضلك ادخل باقي البيانات');
      console.log("form is still invalid")

    
    } else if (value.valid == true) {
      this.register();
    }
  }


    
  register(){
    // console.log("formValue=> ",formValue)
 
    let submitobj ={
      "old_password":this.registrationForm.value['old_password'],
      "password":this.registrationForm.value['password'],
      "password_confirmation":this.registrationForm.value['confirm_password'],
      "module_id":421,
      "id": this.UserID 
    }
    console.log("object of change password api :-",submitobj)
    this.authService.submitchangePasswordForm(submitobj).subscribe((res:any)=>{
      console.log("Register Form Response",res);

      if(res.status.code === 200){
          this.toastr.success(res.status.message);
          // localStorage.setItem('login_token','')

          this.router.navigateByUrl('/login');   
          console.log (res.status.message) 
        }
      else if (res.status.code === 400){
          this.toastr.error(res.status.error_details);
        }  
      else if (res.status.code === 401){
        this.toastr.error(res.status.error_details);
      }
      else if (res.status.code === 403){
        let validation_error = res.status.validation_errors;
         console.log(res.status.validation_errors)
        validation_error.forEach(element => {

          if(element.field == 'old_password') {
            this.toastr.error(element.message);
          }

          if(element.field == 'password') {
            this.toastr.error(element.message);
          }

          else if(element.field == 'password_confirmation') {
            this.toastr.error(element.message);
          }

          else if(element.field == 'module_id') {
            this.toastr.error(element.message);
          }

          else if(element.field == 'id') {
            this.toastr.error(element.message);
          }

         

         

        })
        


      }
    })
  }



}
