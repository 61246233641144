import { Injectable } from '@angular/core';
import { BaseHttpService } from 'src/app/services/base-http.service';


@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private baseHttpService: BaseHttpService) { }
  
  getProductsList() {
    return this.baseHttpService.post('/api/cms', {
      "module_id": 480
    }, {})
  }



}
