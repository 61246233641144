<!-- Footer -->
<footer class="page-footer font-small mdb-color pt-4 color-style">

    <!-- Footer Links -->
    <div class="container text-center text-md-left">

        <!-- Footer links -->
        <div class="row text-center text-md-left mt-3 pb-3">

            <!-- Grid column -->
            <div class="col-md-2 col-lg-2 col-xs-12 text-center mx-auto mt-3">
                <a class="footer-brand" routerLink="/home"><img src="assets/images/logo.PNG"></a>

            </div>

            <!-- Grid column -->
            <div class="col-md-6 col-lg-6 col-xs-12 mx-auto mt-3 text-left">
                <!-- <h6 class="text-uppercase mb-4 font-weight-bold">Useful links</h6> -->
                <ul class="edit">
                    <li routerLink="/home">{{'FOOTER.HOME'|translate}}</li>
                    <li routerLink="/about">{{'FOOTER.ABOUT_ETAAM'|translate}}</li>
                    <li routerLink="/articles">الأخبار</li>
                    <li routerLink="/event">الفعاليات</li>
                    <li routerLink="/initiatives">مبادرات الصندوق</li>
                    <li routerLink="/support">دعم الصندوق</li>
                    <!-- <li routerLink="/associations"> جمعيات تحفيظ القرآن</li> -->
                    <li routerLink="/recruitment"> التوظيف</li>
                    <!-- <li> الموردين</li> -->

                    <li routerLink="/products">  المنتجات</li>
                    <li routerLink="/friendly-websites"> المواقع الصديقة</li>

                    <li routerLink="/terms&conditions">الشروط والأحكام</li>
                    <li routerLink="/privacy-policy">سياسة الخصوصية</li>
                    
                </ul>
                
                <div class="subscribe_form" >
                    <form [formGroup]="SubscribeForm" style="margin: 0px;width:100%">
                        <input type="text" formControlName="email" placeholder="القائمة البريدية" class="form-control subscribe" style="border-radius: 0px 7px 7px 0px !important;">
                        <button class="subscribe-btn" (click)="PostSubscribeEmail()" > <i class="fa fa-plus" style="margin: 0px 5px;"></i>أضف</button>
                    </form>
                </div> 
            </div>

            <!-- Grid column -->
            <hr class="w-100 clearfix d-md-none">

            <!-- Grid column -->
            <div class="col-md-3 col-lg-3 col-xs-12 mx-auto mt-3 text-left">
                
                <!-- <h6 class="text-uppercase mb-4 font-weight-bold">Contact</h6> -->
                <p style="color:white">
                    <i class="fa fa-home "></i>
                     {{contactData?.address}}
                </p>
                <p style="color:white">
                    <i class="fa fa-envelope "></i> 
                    {{contactData?.email}}
                </p>
                <p style="color:white;" class="Numbers-font">
                    <i class="fa fa-phone "></i> 
                    {{contactData?.mobile}}
                </p>
                
                <!-- Social buttons -->
                <div class="text-center social">
                    <ul class="list-unstyled list-inline">
                        
                        <li class="list-inline-item" *ngFor="let link of SocialLinks">
                            <a class="btn-floating btn-sm rgba-white-slight" href="{{link.value}}" target="_blank">
                               
                                <i *ngIf="link.type.name == 'whatsapp' " class="fab fa-whatsapp"></i>
                                <i *ngIf="link.type.name == 'facebook' " class="fa fa-facebook"></i>
                                <i *ngIf="link.type.name == 'twitter' " class="fa fa-twitter"></i>
                                <i *ngIf="link.type.name == 'youtube' " class="fab fa-youtube" style="padding: 10px 8px !important;"></i>
                                <i *ngIf="link.type.name == 'instagram' " class="fa fa-instagram"></i>
                                <i *ngIf="link.type.name == 'snapchat' " class="fa fa-snapchat"></i>
                                <i *ngIf="link.type.name == 'telegram' " class="fab fa-telegram-plane"></i>
                                <i *ngIf="link.type.name == 'linkedin' " class="fab fa-linkedin-in"></i>
                                <i *ngIf="link.type.name == 'vimeo' " class="fab fa-vimeo-v"></i>

                            </a>
                        </li>

                        <!-- <li class="list-inline-item" *ngIf=" facebooklink != ' ' ">
                            <a class="btn-floating btn-sm rgba-white-slight" href="{{facebooklink}}" target="_blank">
                                <i class="fa fa-facebook"></i>
                            </a>
                        </li>
                        <li class="list-inline-item" *ngIf="twitterlink != ' ' ">
                            <a class="btn-floating btn-sm rgba-white-slight" href="{{twitterlink}}" target="_blank">
                                <i class="fa fa-twitter"></i>
                            </a>
                        </li>
                        <li class="list-inline-item" *ngIf=" youtubelink != ' ' ">
                            <a class="btn-floating btn-sm rgba-white-slight" href="{{youtubelink}}" target="_blank">
                                <i class="fab fa-youtube"></i>
                            </a>
                        </li>
                        <li class="list-inline-item" *ngIf=" instagramlink != ' ' ">
                            <a class="btn-floating btn-sm rgba-white-slight" href="{{instagramlink}}" target="_blank">
                                <i class="fa fa-instagram"></i>
                            </a>
                        </li>
                        <li class="list-inline-item" *ngIf=" snapchatlink != ' ' ">
                            <a class="btn-floating btn-sm rgba-white-slight" href="{{snapchatlink}}" target="_blank">
                                <i class="fa fa-snapchat"></i>
                            </a>
                        </li> -->

                        <li></li>
                    </ul>
                </div>

            </div>
            <!-- Grid column -->



        </div>
        <!-- Footer links -->
        <div class="col-md-6 col-lg-8 col-sm-12 col-xs-12">
            <div class="visitors-wrapper">
                <span class="title">عدد الزوار</span>
                <div class="counter">
                    <i class="fa fa-user"></i>
                    <span id="visitors" class="visitors Numbers-font">{{visitors}}</span>
                </div>
            </div>
        </div>
        <hr>

        <!-- Grid row -->
        <div class="row d-flex align-items-center">

            <!-- Grid column -->
            <!-- <div class="col-md-6 col-lg-4 ml-lg-0">

                

            </div> -->
            <!-- Grid column -->

            <!-- Grid column -->
            
            <div class="col-md-12 col-lg-12">

                <!--Copyright-->
                <p class="text-center" style="color:white">
                    © جميع الحقوق محفوظة لموقع صندوق العناية بالقرآن الكريم 2020
                    <!-- {{'FOOTER.COPY_RIGHT'|translate}} <a class="footer-link" href="https://pentavalue.com/en">{{'FOOTER.PENTAVALUE'|translate}}</a> {{'FOOTER.ALL_RIGHT'|translate}} -->
                </p>

            </div>
            <!-- Grid column -->

        </div>
        <!-- Grid row -->

    </div>
    <!-- Footer Links -->

</footer>
<!-- Footer -->
<!-- <app-donate-popup *ngIf="myprofile" (closePopup)='closeProfile($event)'> </app-donate-popup> -->
