import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SwiperModule, SwiperConfigInterface, SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { CarouselModule } from 'ngx-owl-carousel-o';
//Custom Modules and components
import { AuthenticationModule } from '../authentication/authentication.module';
import { NgxPaginationModule } from 'ngx-pagination';

import { EtaamFooterComponent } from './components/etaam-footer/etaam-footer.component';
import { EtaamHeaderComponent } from './components/etaam-header/etaam-header.component';
import { CardEventOpportunityComponent } from './components/card-event-opportunity/card-event-opportunity.component';
import { CardOpportunityComponent } from './card-opportunity/card-opportunity.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateFormatterPipe } from './pipes/date-formatter.pipe';
import { ArticleCardComponent } from '../articles/components/article-card/article-card.component';
import { CardEventComponent } from '../events/components/card-event/card-event.component';
import { LoaderComponent } from './components/loader/loader.component';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderService } from '../shared/shared_services/loader.service';
import { NumberFormatterPipe } from './pipes/numberFormatter.pipe';





const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};
@NgModule({
  declarations: [
    EtaamFooterComponent,
    EtaamHeaderComponent,
    CardEventOpportunityComponent,
    CardOpportunityComponent,
    DateFormatterPipe,
    CardEventComponent,
    ArticleCardComponent,
    LoaderComponent,
    NumberFormatterPipe
  ],
  exports:[
    EtaamFooterComponent,
    EtaamHeaderComponent,
    CardEventOpportunityComponent,
    CardOpportunityComponent,
    CardEventComponent,
    ArticleCardComponent,
    NgxPaginationModule,
    DateFormatterPipe,
    NumberFormatterPipe,
    LoaderComponent,

  ],
  imports: [
    CommonModule,
    SwiperModule,
    CarouselModule,
    TranslateModule.forChild(),
    AuthenticationModule,
    RouterModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
   
    MatProgressSpinnerModule,



  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
    LoaderService
  ],
})
export class SharedModule { }
