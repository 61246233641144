import { Injectable } from '@angular/core';
import { BaseHttpService } from 'src/app/services/base-http.service';
import { HttpHeaders,HttpRequest,HttpParams  } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class VerificationService {

  constructor(private baseHttpService: BaseHttpService) { }

  SendEmailVerification(param) {
    let obj ={
      "email_verification_code" :param
    }
    return this.baseHttpService.post('/api/verify_email_verification_code',{ ...obj}, {})
  }
}
