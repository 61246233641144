import { Injectable } from '@angular/core';
import { BaseHttpService } from 'src/app/services/base-http.service';
import * as moment from 'moment';
@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private baseHttpService: BaseHttpService) { }

  getNews() {
    return this.baseHttpService.post('/api/cms', {
      "module_id": 461,
      // "content": "التنفيذى",
      // "to_date": moment().format('YYYY-MM-DD'),
      "page_size": 4
    }, {
    })
  }

  getEvents() {
    return this.baseHttpService.post('/api/cms', {
      "module_id": 462,
      "page_size": 4
    }, {
    })
  }

  getSupportData() {
    return this.baseHttpService.post('/api/pages/show', {
      "id" : 15
    }, {
    })
  }

  getHomeSlider() {
    return this.baseHttpService.post('/api/cms', {
      "module_id": 483
    }, {})
  }

  getInitiatives() {
    return this.baseHttpService.post('/api/initiatives', {
      "module_id": 489
    }, {})
  }

  getAssociations() {
    return this.baseHttpService.post('/api/associations', {
      "module_id": 494,
      "lang_id": 1,
      "pagination": 10
    }, {})
  }
}
