import { Injectable } from '@angular/core';
import { BaseHttpService } from 'src/app/services/base-http.service';

@Injectable({
  providedIn: 'root'
})
export class InitiativesService {

  constructor(private baseHttpService: BaseHttpService) { }
  
  
  getInitiatives(currentPage) {
    let baseEventsObject = {
      "module_id": 489
    }
    return this.baseHttpService.post('/api/initiatives', baseEventsObject, {
      params: {
        page: currentPage
      }
    });
  }

  getInitativeByID(id) {
    return this.baseHttpService.post('/api/initiatives/show', {
      "module_id": 489,
      "id": id
    }, {})
  }

  getSearchInitiatives(currentPage,search) {
    let baseEventsObject = {
      "module_id": 489,
      "name":search
    }
    return this.baseHttpService.post('/api/initiatives', baseEventsObject, {
      params: {
        page: currentPage
      }
    });
  }

  getAdvancedSearchInitiatives(currentPage,searchData){
    let baseEventsObject = {
      "module_id": 489,
      "name":searchData.name,
      "content" :searchData.content,
      "from_date": searchData.from_date,
      "to_date": searchData.to_date
    }
    return this.baseHttpService.post('/api/initiatives', baseEventsObject, {
      params: {
        page: currentPage
      }
    });
  }


}
