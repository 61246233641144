import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PopupComponent } from './shared/popup/popup.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { DonatePopupComponent } from './shared/donate-popup/donate-popup.component';
// multi steps form imports
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
// import {TranslateModule} from '@ngx-translate/core';



import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NbThemeModule, NbLayoutModule } from '@nebular/theme';



import { RatingModule } from 'ng-starrating';

// import {StarRatingModule} from 'angular-star-rating';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { GeneralPagesModule } from './general-pages/general-pages.module';
import { SharedModule } from './shared/shared.module';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { HttpInterceptorService } from './services/http-interceptor.service';

import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// import { NgxTwitterTimelineModule } from 'ngx-twitter-timeline';
// import TwitterTimelineData from 'TwitterTimelineData';
// import TwitterTimelineOptions from 'TwitterTimelineOptions';

// import {CarouselModule} from 'primeng/carousel';
import {ButtonModule} from 'primeng/button';
import {ToastModule} from 'primeng/toast';


// import { LoaderComponent } from '../app/shared/components/loader/loader.component';

import { EmbedVideo } from 'ngx-embed-video';
import { AccordionModule } from 'ngx-bootstrap/accordion';




const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    PopupComponent,
    DonatePopupComponent,
    // LoaderComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    BrowserAnimationsModule,
    MatButtonModule,
    SharedModule,
    SwiperModule,
    CarouselModule,
    ButtonModule,
    ToastModule,
    MatSelectModule,
    NgMultiSelectDropDownModule.forRoot(),
    RatingModule,
    GeneralPagesModule,
    NbThemeModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NbThemeModule.forRoot({ name: 'default' }),
    NbLayoutModule,
    NbEvaIconsModule,
    ToastrModule.forRoot(),
    NgbModule ,
    EmbedVideo.forRoot(),
    AccordionModule.forRoot(),
   
    


  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
  ],
  // exports:[
  //   BotDetectCaptchaModule
  // ],
  bootstrap: [AppComponent]
})
export class AppModule {

 }
