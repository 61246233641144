import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseHttpService } from 'src/app/services/base-http.service';

@Injectable({
  providedIn: 'root'
})
export class GrantsService {
  header:HttpHeaders = new HttpHeaders();


  constructor(private baseHttp: BaseHttpService) { }

  getGrantsData() {
    return this.baseHttp.post('/api/grant_info', {
      "lang_id": 2,
      "module_id": 498
    }, {})
  }

  getGrantPaths() {
    return this.baseHttp.post('/api/grant_paths', {
      "lang_id": 2,
      "module_id": 501
    }, {})
  }

  getGrantPathDomains(grantID) {
    return this.baseHttp.post('/api/grant_domains', {
      "lang_id": 2,
      "module_id": 502,
      "grant_path_id": grantID
    }, {})
  }

  getGrantBanks() {
    return this.baseHttp.post('/api/grant_banks', {
      "lang_id": 2,
      "module_id": 509
    }, {});
  }

  submitGrant(grantData) { // step 2
    return this.baseHttp.post('/api/grants/store', {
      ...grantData, "lang_id": 2,
      "module_id": 497,

    }, {})
  }

  submitGrant2(grantData){
    let headerNotification = this.header.set("access-token",localStorage.getItem('login_token'))

    return this.baseHttp.post('/api/grants/update', {
      ...grantData,
       "lang_id": 2,
       "module_id": 497,

    },{headers:headerNotification})

  }

  showGrant(grantID) {
    return this.baseHttp.post('/api/grants/show', {
      "id": grantID,
      "module_id": 497,
      lang_id: 2
    })
  }

  submitGrantFiles(grantData) {
    return this.baseHttp.post('/api/grants_documents/store', {
      ...grantData, "lang_id": 2,
      "module_id": 497,

    }, {})
  }

  submitFinalReport(reportData) {
    return this.baseHttp.post('/api/grants/final_report/store', {
      ...reportData,
      "module_id": 497
    }, {})
  }

  EditGrant(grantData) {
    let headerNotification = this.header.set("access-token",localStorage.getItem('login_token'))

    return this.baseHttp.post('/api/grants/update', {
      ...grantData,
      "lang_id": 2,
      "module_id": 497,

    }, {headers:headerNotification})
  }

  getGrantPrintFile(grantID) {
    return this.baseHttp.get(`/api/grants/pdf/${grantID}`, {
      headers: new HttpHeaders().delete('Content-Type')
    });
  }


  getEtisalatCompanies() {
    return this.baseHttp.post('/api/association_financial_reports/companies', {
      "lang_id": 2,
      "module_id": 482
    })
  }

  getFinanceReports(filters) {
    return this.baseHttp.post('/api/association_financial_reports', {
      "lang_id": 2,
      "module_id": 520,
      "association_id": localStorage.getItem('association_id'),
      ...filters
    });
  }
  exportFinanceReport() {
    return this.baseHttp.get('/association_financial_reports/export', 
           {
              headers: new HttpHeaders().delete('Content-Type').set('access-token', localStorage.getItem('login_token')),
              observe: 'response', responseType: 'blob'
           }
    );
  }

  exportFinanceReportAsPDF() {
    /* let headerNotification = this.header.set("access-token",localStorage.getItem('login_token'))
                                        .delete('Content-Type'),observe: 'response', responseType: 'blob' */

    return this.baseHttp.get('/api/association_financial_reports/print', 
           {
              headers: new HttpHeaders().delete('Content-Type').set('access-token', localStorage.getItem('login_token')),
              observe: 'response', responseType: 'text'
           }
    );
  }

  GetSMSLink(){
    return this.baseHttp.post('/api/links/show', {
       "application_id": 25,
       "module_id": 522,
       "id": 1
    })
  }

  importExcel(file){
    let headerNotification = this.header.set("access-token",localStorage.getItem('login_token'))

    return this.baseHttp.post('/api/association_financial_reports/import', {
      "application_id": 25,
      "excel_file":file
    },{headers:headerNotification
    })
  }




}
