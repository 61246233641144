import { Injectable } from '@angular/core';
import { BaseHttpService } from 'src/app/services/base-http.service';
import * as moment from 'moment';
import { HttpParams } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ArticlesService {
  constructor(private baseHttpService: BaseHttpService) { }

  getNews(currentPage = 1) {
    let baseNewsObject = {
      "module_id": 461,
      // "content": "التنفيذى",
      // "to_date": moment().format('YYYY-MM-DD')
    }
    return this.baseHttpService.post('/api/cms', baseNewsObject, {
      params: {
        page: currentPage
      }
      
    });
  }

  getNewsById(articleID) {
    let articleByIDObject = {
      "id": articleID,
      "module_id": 461
    }
    return this.baseHttpService.post('/api/cms/show', articleByIDObject, {});
  }

  getSearchNews(search) {
    let baseEventsObject = {
      "application_id": 25,
      "lang_id": 2,
      "module_id": 461,
      "title":search
    }
    return this.baseHttpService.post('/api/cms',baseEventsObject, {
     
    });
  }

  getAdvancedSearchNews(searchData){
    let baseEventsObject = {
      "application_id": 25,
      "lang_id": 2,
      
      "module_id": 461,
      "title":searchData.name,
      "content" :searchData.content,
      "from_date": searchData.from_date,
      "to_date": searchData.to_date
    }
    return this.baseHttpService.post('/api/cms',baseEventsObject, {
      
    });
  }

  getSearchInitiatives(currentPage = 1,search) {
    let baseEventsObject = {
      "module_id": 489,
      "title":search
    }
    return this.baseHttpService.post('/api/initiatives', baseEventsObject, {
      params: {
        page: currentPage
      }
    });
  }

  getAdvancedSearchInitiatives(currentPage = 1,searchData){
    let baseEventsObject = {
      "module_id": 489,
      "name":searchData.name,
      "content" :searchData.content,
      "from_date": searchData.from_date,
      "to_date": searchData.to_date
    }
    return this.baseHttpService.post('/api/initiatives', baseEventsObject, {
      params: {
        page: currentPage
      }
    });
  }

  getRelatedNews(articleID) {
    let baseNewsObject = {
      "module_id": 461,
      "id": articleID,
    }
    return this.baseHttpService.post('/api/cms/related', baseNewsObject, {}
    );
  }

}
