import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AboutComponent } from './pages/about/about.component';
import { ContactComponent } from './pages/contact/contact.component';
import { PartnersComponent } from './pages/partners/partners.component';
import { CeoMessageComponent } from './pages/ceo-message/ceo-message.component';
import { TeamComponent } from './pages/team/team.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TeamMemberComponent } from './components/team-member/team-member.component';
import { LibraryComponent } from './pages/library/library.component';
import { LibraryCardComponent } from './components/library-card/library-card.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { HomeComponent } from './pages/home/home.component';
import { SwiperModule, SWIPER_CONFIG, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { DonationCardComponent } from './components/donation-card/donation-card.component';
import { FriendlyWebsiteComponent } from './pages/friendly-website/friendly-website.component';
import { RouterModule } from '@angular/router';
import { ProductsComponent } from './pages/products/products.component';
import { TalabatComponent } from './pages/talabat/talabat/talabat.component';

import { DataTableModule } from 'angular7-data-table';
import { SharedModule } from '../shared/shared.module';
import { TermsComponent } from './pages/terms/terms.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { OrderByIdComponent } from './pages/order-by-id/order-by-id.component';
import { HomeSearchComponent } from './pages/home-search/home-search.component';
import { SearchComponent } from './pages/search/search.component';

import { Ng2SmartTableModule } from 'ng2-smart-table';

// import { NgxTwitterTimelineModule } from 'ngx-twitter-timeline';


import { BotDetectCaptchaModule } from 'angular-captcha'; 


const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};
@NgModule({
  declarations: [AboutComponent, ContactComponent, PartnersComponent, CeoMessageComponent, TeamComponent, TeamMemberComponent, LibraryComponent, LibraryCardComponent, HomeComponent, DonationCardComponent, FriendlyWebsiteComponent, ProductsComponent, TalabatComponent, OrderByIdComponent, TermsComponent, PrivacyPolicyComponent, HomeSearchComponent, SearchComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    SwiperModule,
    CarouselModule,
    RouterModule,
    DataTableModule.forRoot(),

    Ng2SmartTableModule,
    
    // NgxTwitterTimelineModule


  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ]

})
export class GeneralPagesModule { }
