    <a [routerLink]="['/event', eventDetails.id]">
        <div class="card">
            <div class="card-img-top" [ngStyle]="{'background-image': 'url('+ eventDetails?.image +')'}"></div>
            <!-- <div class="option" (click)="viewProfile()">
                <i class="fa fa-ellipsis-v"></i>
            </div> -->
            <div class="card-body ">
                <div class="row">
                    <div class="col-md-3 col-sm-3 xol-xs-3">
                        <time datetime="" class="icon" >
                           
                            <em>{{eventDetails.start_date | dateFormatter: 'ddd':'ar'}}</em>
                            <strong>{{eventDetails.start_date | dateFormatter: 'MMM':'ar'}}</strong>
                            <span>{{eventDetails.start_date | dateFormatter: 'D':'ar'}}</span>
                        </time>
                        <!-- <time datetime="" class="icon" *ngIf="eventDetails?.module_calendar == 1 ">
                           
                            <em>{{eventDetails.start_date_hijri | slice:8:10 }}</em>
                            <strong>{{eventDetails.start_date_hijri | slice:5:7}}</strong>
                            <span>{{eventDetails.start_date_hijri | slice:0:4}}</span>
                        </time> -->
                    </div>
                    <div class="col-md-9 col-sm-9 col-xs-9 ">
                        <div class="event-desc text-left">
                            <h4 class="card-title"> {{eventDetails.title}} </h4>
                            <p > <i class="fa fa-map-marker"></i>&nbsp; {{eventDetails.address}} </p>
                            <a class="more" [routerLink]="['/event', eventDetails.id]">{{'HOME.VIEW_DETAILS'|translate}} <i class="fa fa-arrow-circle-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
             <!-- <app-card-event-popup *ngIf="myprofile" (valueChange)='closeProfile($event)' [eventID]="eventDetails.id"></app-card-event-popup> -->
        </div>
    </a>


