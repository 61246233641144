<a [routerLink]="[article.id]">
    <div class="card text-left" style="height: 350px;">
        <img class="card-img-top" [src]="article.image">
        <div class="card-body">
            <div style ="display: flex;">

                <!-- <time datetime="" class="icon">
                        
                    <em>{{article.created_at | dateFormatter: 'ddd':'ar'}}</em>
                    <strong>{{article.created_at | dateFormatter: 'MMM':'ar'}}</strong>
                    <span>{{article.created_at | dateFormatter: 'D':'ar'}}</span>
                </time> -->
                
                <h3 class="card-title">{{article.title}}</h3>

            </div>

                <p class="p-date" *ngIf="article.module_calendar == 1 ">{{article.start_date}}</p>
                <p class="p-date" *ngIf="article.module_calendar == 2 ">{{article.start_date_hijri}}</p>
                <p class="p-date" *ngIf="article.module_calendar == 3 ">
                     <span>{{article.start_date}}</span>
                     <span style="float: right;">{{article.start_date_hijri}}</span>
                     <span style="padding: 0px 10px;float: right;">الموافق</span>


                </p>



                
                <p [innerHTML]="article.content" class=""></p>
                <!-- <img src="assets/images/Keion-Jackson.jpg" class="rounded-circle" alt="Cinque Terre">
                <p class="p-title1"> {{article.author}} </p> -->

        </div>
    </div>
</a>
