// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  pusher: {
    key: 'fc9f24eb1b8cb8fd79ee',
    cluster: 'eu',
  },
  // BASE_URL: "https://cors-anywhere.herokuapp.com/https://appox.xyz/system/pentalibrary/public"
  BASE_URL: "https://appox.xyz/system/pentalibrary/public"

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
