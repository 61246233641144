<div class="full-width">
    <div class="page-title">
        <div class="container">
            <h3>
                تغيير  كلمة المرور
        </h3>
        </div>
    </div>
</div>
    <div class="register internal-page">
        <div class="container">
            <form [formGroup] = "registrationForm" (ngSubmit)="submit(registrationForm)">
                <fieldset _ngcontent-dyb-c300="" class="scheduler-border">
                    <!-- <legend _ngcontent-dyb-c300="" class="scheduler-border wizard--title"> {{'AUTH.REGISTER.REGISTRATION'|translate}} </legend> -->
                    
                    <div class="row">
                        <div class="col-md-12 col-sm-12 col-xs-12 text-left">
                            <div class="form-group">
                                <label class="font--bold">{{'old password'|translate}}</label>
                                <input  [type]="oldpassword"
                                        class="form-control"
                                        placeholder="{{'old password'|translate}}"
                                        formControlName="old_password">
                                <span toggle="#password-field" class="fa fa-fw fa-eye field-icon toggle-password"
                                        (click)="changoldPasswordVisiabilty()"></span>

                                <span *ngIf="registrationForm.get('old_password').touched && registrationForm.get('old_password').invalid">
                                    <small *ngIf="registrationForm.get('old_password').errors.required"  class="form-text required">
                                                {{'VALIDATORS.REQUIRED'|translate}}
                                    </small>
                                </span>        
                            </div>

                        </div>
                        
                    </div>
                    

                    <div class="row">
                        <div class="col-md-12 col-sm-12 col-xs-12 text-left">
                            <div class="form-group">
                                <label class="font--bold">{{'AUTH.REGISTER.password'|translate}}</label>
                                <input  [type]="password"
                                        class="form-control"
                                        placeholder="{{'AUTH.REGISTER.password'|translate}}"
                                        formControlName="password">
                               <span toggle="#password-field" class="fa fa-fw fa-eye field-icon toggle-password"
                                      (click)="changPasswordVisiabilty()"></span>

                                <span *ngIf="registrationForm.get('password').touched && registrationForm.get('password').invalid">
                                    <small *ngIf="registrationForm.get('password').errors.required"  class="form-text required">
                                                {{'VALIDATORS.REQUIRED'|translate}}
                                    </small>
                                    <small *ngIf="registrationForm.get('password').errors.pattern" class="form-text required">
                                        {{' برجاء ادخال رمز سري مكون من 8 أحرف على الاقل و يشمل احرف كبيرة وصغيرة وارقام ورموز '}} 
                                    </small>
                                </span>        
                            </div>
                        </div>

                        
                        <div class="col-md-12 col-sm-12 col-xs-12 text-left">
                            <div class="form-group">
                                <label class="font--bold">{{'AUTH.REGISTER.repeatpassword'|translate}}</label>
                                <input  [type]="confirmPassword"
                                        class="form-control"
                                        placeholder="{{'AUTH.REGISTER.repeatpassword'|translate}}"
                                        formControlName="confirm_password">
                                <span toggle="#password-field" class="fa fa-fw fa-eye field-icon toggle-password"
                                        (click)="changConfirmPasswordVisiabilty()"></span>
                                
                                <span *ngIf="registrationForm.get('confirm_password').touched && registrationForm.get('confirm_password').invalid">
                                    <small *ngIf="registrationForm.get('confirm_password').errors.required"  class="form-text required">
                                        {{'VALIDATORS.REQUIRED'|translate}}
                                    </small>
                                    <small *ngIf="registrationForm.get('confirm_password').errors.pattern" class="form-text required">
                                        {{' برجاء ادخال رمز سري مكون من 8 أحرف على الاقل و يشمل احرف كبيرة وصغيرة وارقام ورموز '}} 
                                    </small>
                                    <small *ngIf="registrationForm.get('confirm_password').errors.notMatch" class="form-text required">
                                        يجب ان يتطابق مع الرمز السري
                                      </small>
                                </span>         
                            </div>
                        </div>


                    </div>

                   
                    <div class="row">
                        <div class="col-md-12 col-sm-12 col-xs-12 text-left">
                            <button class="btn-custom" type="submit">{{'AUTH.REGISTER.SEND'|translate}}</button>
                        </div>
                    </div>


                </fieldset>
            </form>
        </div>

    </div>
